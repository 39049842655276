@import "./variables";
@import "./utils";

$screen-sm-em: #{rem(map-get($breakpoints, "break-1"), false, true)}em;
$screen-md-em: #{rem(map-get($breakpoints, "break-2"), false, true)}em;
$screen-lg-em: #{rem(map-get($breakpoints, "break-3"), false, true)}em;
$screen-xl-em: #{rem(map-get($breakpoints, "break-4"), false, true)}em;

.container {
  @include clearfix;
  width: 100%;
  padding-left: rem($grid-gutter-width-sm);
  padding-right: rem($grid-gutter-width-sm);

  @media (min-width: $screen-md) {
    padding-left: rem($grid-gutter-width-md);
    padding-right: rem($grid-gutter-width-md);
  }

  @media (min-width: $screen-lg) {
    padding-left: rem($grid-gutter-width-lg);
    padding-right: rem($grid-gutter-width-lg);
  }

  @media (min-width: $screen-xl) {
    padding-left: rem($grid-gutter-width-xl);
    padding-right: rem($grid-gutter-width-xl);
  }
}

.gutter-left {
  margin-left: #{rem(($grid-gutter-width-sm / 2))};

  @media (min-width: $screen-md) {
    margin-left: #{rem(($grid-gutter-width-md / 2))};
  }

  @media (min-width: $screen-lg) {
    margin-left: #{rem(($grid-gutter-width-lg / 2))};
  }

  @media (min-width: $screen-xl) {
    margin-left: #{rem(($grid-gutter-width-xl / 2))};
  }
}

.gutter-right {
  margin-right: #{rem(($grid-gutter-width-sm / 2))};

  @media (min-width: $screen-md) {
    margin-right: #{rem(($grid-gutter-width-md / 2))};
  }

  @media (min-width: $screen-lg) {
    margin-right: #{rem(($grid-gutter-width-lg / 2))};
  }

  @media (min-width: $screen-xl) {
    margin-right: #{rem(($grid-gutter-width-xl / 2))};
  }
}

.row {
  box-sizing: border-box;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  flex-direction: row;
  -webkit-flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  margin-right: getProperty($flexBoxGrid, "gutter-compensation-xs", false, true);
  margin-left: getProperty($flexBoxGrid, "gutter-compensation-xs", false, true);

  @media only screen and (min-width: $screen-sm-em) {
    margin-right: getProperty($flexBoxGrid, "gutter-compensation-sm", false, true);
    margin-left: getProperty($flexBoxGrid, "gutter-compensation-sm", false, true);
  }
  @media only screen and (min-width: $screen-md-em) {
    margin-right: getProperty($flexBoxGrid, "gutter-compensation-md", false, true);
    margin-left: getProperty($flexBoxGrid, "gutter-compensation-md", false, true);
  }
  @media only screen and (min-width: $screen-lg-em) {
    margin-right: getProperty($flexBoxGrid, "gutter-compensation-lg", false, true);
    margin-left: getProperty($flexBoxGrid, "gutter-compensation-lg", false, true);
  }
  @media only screen and (min-width: $screen-xl-em) {
    margin-right: getProperty($flexBoxGrid, "gutter-compensation-xl", false, true);
    margin-left: getProperty($flexBoxGrid, "gutter-compensation-xl", false, true);
  }

  &.margin-bottom {
    //margin-bottom: rem(map-get($spacing, "mobile"));
    @media screen and (min-width: $screen-tablet) {
      //margin-bottom: rem(map-get($spacing, "tablet"));
    }
    @media screen and (min-width: $screen-desktop) {
      //margin-bottom: rem(map-get($spacing, "desktop"));
    }
  }

  // add vertical padding to columns
  [class*="col-"] {
    // padding-bottom: rem(map-get($spacing, "mobile"));
    @media screen and (min-width: $screen-tablet) {
      padding-bottom: rem(map-get($spacing, "tablet"));
    }
    @media screen and (min-width: $screen-desktop) {
      // padding-bottom: rem(map-get($spacing, "desktop"));
      padding-bottom: 3.125rem;
    }
  }

  // negate vertical padding on columns on last row
  &:last-child {
    margin-bottom: rem(-1 * map-get($spacing, "mobile"));
    @media screen and (min-width: $screen-tablet) {
      margin-bottom: rem(-1 * map-get($spacing, "tablet"));
    }
    @media screen and (min-width: $screen-desktop) {
      margin-bottom: rem(-1 * map-get($spacing, "desktop"));
    }
  }
}

@mixin make-grid-columns($i: 1) {
  @include make-grid(xs);
  @media only screen and (min-width: $screen-sm-em) {
    @include make-grid(sm);
  }
  @media only screen and (min-width: $screen-md-em) {
    @include make-grid(md);
  }
  @media only screen and (min-width: $screen-lg-em) {
    @include make-grid(lg);
  }
  @media only screen and (min-width: $screen-xl-em) {
    @include make-grid(xl);
  }
}

// Create grid for specific class
@mixin make-grid($class) {
  [class*="col-#{$class}"] {
    box-sizing: border-box;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    padding-right: getProperty($flexBoxGrid, "half-gutter-width-sm", false, true);
    padding-left: getProperty($flexBoxGrid, "half-gutter-width-sm", false, true);

    @media only screen and (min-width: $screen-md-em) {
      padding-right: getProperty($flexBoxGrid, "half-gutter-width-md", false, true);
      padding-left: getProperty($flexBoxGrid, "half-gutter-width-md", false, true);
    }
    @media only screen and (min-width: $screen-lg-em) {
      padding-right: getProperty($flexBoxGrid, "half-gutter-width-lg", false, true);
      padding-left: getProperty($flexBoxGrid, "half-gutter-width-lg", false, true);
    }
    @media only screen and (min-width: $screen-xl-em) {
      padding-right: getProperty($flexBoxGrid, "half-gutter-width-xl", false, true);
      padding-left: getProperty($flexBoxGrid, "half-gutter-width-xl", false, true);
    }
  }
  .col-#{$class} {
    flex-grow: 1;
    -webkit-flex-grow: 1;
    flex-basis: 0;
    -webkit-flex-basis: 0;

    max-width: 100%;
  }
  // create
  @include loop-grid-columns(getProperty($flexBoxGrid, "columns"), $class, width);
  @include loop-grid-columns(getProperty($flexBoxGrid, "columns"), $class, pre);
  @include loop-grid-columns(getProperty($flexBoxGrid, "columns"), $class, post);

  .start-#{$class} {
    justify-content: flex-start;
    text-align: start;
  }

  .center-#{$class} {
    justify-content: center;
    // text-align: center;
  }

  .end-#{$class} {
    justify-content: flex-end;
    text-align: end;
  }

  .top-#{$class} {
    align-items: flex-start;
  }

  .middle-#{$class} {
    align-items: center;
  }

  .bottom-#{$class} {
    align-items: flex-end;
  }

  .around-#{$class} {
    justify-content: space-around;
  }

  .between-#{$class} {
    justify-content: space-between;
  }

  .row.reverse-#{$class} {
    flex-direction: row-reverse;
  }

  .first-#{$class} {
    order: -1;
  }

  .last-#{$class} {
    order: 1;
  }
}

@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 1 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}

@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) {
    .col-#{$class}-#{$index} {
      flex-basis: percentage(($index / getProperty($flexBoxGrid, "columns")));
      -webkit-flex-basis: percentage(($index / getProperty($flexBoxGrid, "columns")));
      max-width: percentage(($index / getProperty($flexBoxGrid, "columns")));
    }
  }
  @if ($type == pre) {
    .col-#{$class}-pre-#{$index} {
      margin-left: percentage(($index / getProperty($flexBoxGrid, "columns")));
    }
  }
  @if ($type == post) {
    .col-#{$class}-post-#{$index} {
      margin-right: percentage(($index / getProperty($flexBoxGrid, "columns")));
    }
  }
}

@include make-grid-columns;

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/

@mixin layout-for-name($name: null) {
  @if $name == null {
    $name: "";
  }
  @if $name != "" {
    $name: "-#{$name}";
  }

  .layout#{$name},
  .layout#{$name}-column,
  .layout#{$name}-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .layout#{$name}-column {
    flex-direction: column;
  }
  .layout#{$name}-row {
    flex-direction: row;
  }
}

@mixin flex-properties-for-name($name: null) {
  $flexName: "flex";
  @if $name != null {
    $flexName: "flex-#{$name}";
    $name: "-#{$name}";
  } @else {
    $name: "";
  }

  .#{$flexName} {
    flex: 1;
    box-sizing: border-box;
  } // === flex: 1 1 0%;

  .#{$flexName}-grow {
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .#{$flexName}-initial {
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .#{$flexName}-auto {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .#{$flexName}-none {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .#{$flexName}-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .#{$flexName}-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .layout#{$name}-row,
  .layout#{$name}-column {
    // Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
    > .flex {
      min-width: 0;
    }
  }
}

@mixin layout-align-for-name($suffix: null) {
  // Alignment attributes for layout containers' children
  // Arrange on the Main Axis
  // center, start, end, space-between, space-around
  // flex-start is the default for justify-content
  // ------------------------------

  $name: "layout-align";
  @if $suffix != null {
    $name: "layout-align-#{$suffix}";
  }

  .#{$name},
    .#{$name}-start-stretch // defaults
  {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }

  // Main Axis Center
  .#{$name}-start,
  .#{$name}-start-start,
  .#{$name}-start-center,
  .#{$name}-start-end,
  .#{$name}-start-stretch {
    justify-content: flex-start;
  }

  // Main Axis Center
  .#{$name}-center,           //stretch
    .#{$name}-center-start,
    .#{$name}-center-center,
    .#{$name}-center-end,
    .#{$name}-center-stretch {
    justify-content: center;
  }

  // Main Axis End
  .#{$name}-end, //stretch
    .#{$name}-end-start,
    .#{$name}-end-center,
    .#{$name}-end-end,
    .#{$name}-end-stretch {
    justify-content: flex-end;
  }

  // Main Axis Space Around
  .#{$name}-space-around, //stretch
    .#{$name}-space-around-center,
    .#{$name}-space-around-start,
    .#{$name}-space-around-end,
    .#{$name}-space-around-stretch {
    justify-content: space-around;
  }

  // Main Axis Space Between
  .#{$name}-space-between, //stretch
    .#{$name}-space-between-center,
    .#{$name}-space-between-start,
    .#{$name}-space-between-end,
    .#{$name}-space-between-stretch {
    justify-content: space-between;
  }

  // Arrange on the Cross Axis
  // center, start, end
  // stretch is the default for align-items
  // ------------------------------

  // Cross Axis Start
  .#{$name}-start-start,
  .#{$name}-center-start,
  .#{$name}-end-start,
  .#{$name}-space-between-start,
  .#{$name}-space-around-start {
    align-items: flex-start;
    align-content: flex-start;
  }

  // Cross Axis Center
  .#{$name}-start-center,
  .#{$name}-center-center,
  .#{$name}-end-center,
  .#{$name}-space-between-center,
  .#{$name}-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }

  // Cross Axis Center IE overflow fix
  .#{$name}-start-center > *,
  .#{$name}-center-center > *,
  .#{$name}-end-center > *,
  .#{$name}-space-between-center > *,
  .#{$name}-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  // Cross Axis End
  .#{$name}-start-end,
  .#{$name}-center-end,
  .#{$name}-end-end,
  .#{$name}-space-between-end,
  .#{$name}-space-around-end {
    align-items: flex-end;
    align-content: flex-end;
  }

  // Cross Axis Start
  .#{$name}-start-stretch,
  .#{$name}-center-stretch,
  .#{$name}-end-stretch,
  .#{$name}-space-between-stretch,
  .#{$name}-space-around-stretch {
    align-items: stretch;
    align-content: stretch;
  }
}

@mixin layouts_for_breakpoint($name: null) {
  @include layout-align-for-name($name);
  @include flex-properties-for-name($name);
  @include layout-for-name($name);
}

/*
  *
  *  Responsive attributes
  *
  *  References:
  *  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
  *  2) https://css-tricks.com/almanac/properties/f/flex/
  *  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
  *  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
  *  5) http://godban.com.ua/projects/flexgrid
  *
  *
  */

@-moz-document url-prefix() {
  .layout-fill {
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
}

/*
   *  Apply Mixins to create Layout/Flexbox styles
   *
   */

@include layouts_for_breakpoint();

.layout-wrap {
  flex-wrap: wrap;
}

.layout-nowrap {
  flex-wrap: nowrap;
}

.layout-fill {
  width: 100%;
  min-height: 100%;
  height: 100%;
}
