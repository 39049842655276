$asset-path: "../assets/fonts/" !default;

$bodytype: (
    font-family: "Helvetica LT Std",
    regular: 400,
    light: 300,
    bold: 700,
    italic: italic,
    base-size: 16,
) !default;

$breakpoints: (
    break-0: 0,
    break-1: 420,
    break-2: 1024,
    break-3: 1280,
    break-4: 1600,
    break-5: 1920,
) !default;

// breakpoint defintion.
$screen-xs: #{map-get($breakpoints, "break-0")}px;
$screen-sm: #{map-get($breakpoints, "break-1")}px;
$screen-md: #{map-get($breakpoints, "break-2")}px;
$screen-lg: #{map-get($breakpoints, "break-3")}px;
$screen-xl: #{map-get($breakpoints, "break-4")}px;
$screen-xxl: #{map-get($breakpoints, "break-5")}px;

$screen-tablet: #{map-get($breakpoints, "break-2")}px;
$screen-desktop: #{map-get($breakpoints, "break-3")}px;

$white: #ffffff;
$black: #000000;
$grey: #c4c4c4;
$light-grey: #f1f2f2;

$gutter: (
    mobile: 20,
    tablet: 40,
    desktop: 60,
) !default;

$row-gap: (
    mobile: 20,
    tablet: 40,
    desktop: 80,
) !default;

$spacing: (
    mobile: 40,
    tablet: 80,
    desktop: 120,
) !default;

$space-between-rows-xs: 80px;
$space-between-rows-xl: 120px;

$flexBoxGrid: (columns: 12,
  gutter-width-xs: 20px,
  gutter-width-sm: 20px,
  gutter-width-md: 40px,
  gutter-width-lg: 60px,
  gutter-width-xl: 60px,
);

$flexBoxGrid: map-merge(
  $flexBoxGrid, (
  gutter-compensation-xs: map-get($flexBoxGrid, 'gutter-width-xs') / 2 * -1,
  gutter-compensation-sm: map-get($flexBoxGrid, 'gutter-width-sm') / 2 * -1,
  gutter-compensation-md: map-get($flexBoxGrid, 'gutter-width-md') / 2 * -1,
  gutter-compensation-lg: map-get($flexBoxGrid, 'gutter-width-lg') / 2 * -1,
  gutter-compensation-xl: map-get($flexBoxGrid, 'gutter-width-xl') / 2 * -1,
  half-gutter-width-xs: map-get($flexBoxGrid, 'gutter-width-xs') / 2,
  half-gutter-width-sm: map-get($flexBoxGrid, 'gutter-width-sm') / 2,
  half-gutter-width-md: map-get($flexBoxGrid, 'gutter-width-md') / 2,
  half-gutter-width-lg: map-get($flexBoxGrid, 'gutter-width-lg') / 2,
  half-gutter-width-xl: map-get($flexBoxGrid, 'gutter-width-xl') / 2,
));

//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width-xs: map-get($flexBoxGrid, 'gutter-width-xs');
$grid-gutter-width-sm: map-get($flexBoxGrid, 'gutter-width-sm');
$grid-gutter-width-md: map-get($flexBoxGrid, 'gutter-width-md');
$grid-gutter-width-lg: map-get($flexBoxGrid, 'gutter-width-lg');
$grid-gutter-width-xl: map-get($flexBoxGrid, 'gutter-width-xl');