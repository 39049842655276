@import "./imports/variables";
@import "./imports/utils";
@import "./imports/reset";
@import "./imports/typography";
@import "./imports/flex-grid";
@import "./imports/error";

.hh-container {
    @include hh-container;
}

.grid {
    @include grid;
}

.sr-only {
    @include sr-only;
}

.spacing {
    @include padding-top;
    @include padding-bottom;
}

.absolute {
   position: absolute;
}

.spacing-top {
    @include padding-top;
}

.spacing-top-full {
    @include padding-top;
}

.spacing-top-half {
    @include padding-top-half;
}

.spacing-bottom {
    @include padding-bottom;
}

.spacing-bottom-full {
    @include padding-bottom;
}

.spacing-bottom-half {
    @include padding-bottom-half;
}

.spacing-full {
    @include padding-top;
    @include padding-bottom;
}

.spacing-half {
    @include padding-top-half;
    @include padding-bottom-half;
}

.main {
    min-height: 100vh;
    z-index: 1;
    position: relative;
    background-color: $white;
    // trying to fix iphone z-index scrolling issue
    -webkit-transform: translate3d(0,0,0);
}

.no-overflow {
    overflow: hidden;
}
