@function rem($size, $round: false, $unitless: false) {
    @if $size == 0 {
        @return $size;
    }
    $type: type-of($size);
    $remSize: ($size + 0px) / 16px;
    @if $round {
        @return round($remSize) + rem;
    }
    @return if($unitless, #{$remSize}, #{$remSize}rem);
}

@mixin aspect-ratio-box($height, $width) {
    &:before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: $height / $width * 100%;
    }
    &:after {
        /* to clear float */
        content: "";
        display: table;
        clear: both;
    }
}

@mixin clear-default-appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    padding: 0;
    background: none;
    border-radius: 0;
}

@mixin padding-top {
    padding-top: rem(map-get($spacing, "mobile"));

    @media screen and (min-width: $screen-tablet) {
        padding-top: rem(map-get($spacing, "tablet"));
    }

    @media screen and (min-width: $screen-desktop) {
        padding-top: rem(map-get($spacing, "desktop"));
    }
}

@mixin padding-bottom {
    padding-bottom: rem(map-get($spacing, "mobile"));

    @media screen and (min-width: $screen-tablet) {
        padding-bottom: rem(map-get($spacing, "tablet"));
    }

    @media screen and (min-width: $screen-desktop) {
        padding-bottom: rem(map-get($spacing, "desktop"));
    }
}

@mixin padding-top-half {
    padding-top: rem(map-get($spacing, "mobile") / 2);

    @media screen and (min-width: $screen-tablet) {
        padding-top: rem(map-get($spacing, "tablet") / 2);
    }

    @media screen and (min-width: $screen-desktop) {
        padding-top: rem(map-get($spacing, "desktop") / 2);
    }
}

@mixin padding-bottom-half {
    padding-bottom: rem(map-get($spacing, "mobile") / 2);

    @media screen and (min-width: $screen-tablet) {
        padding-bottom: rem(map-get($spacing, "tablet") / 2);
    }

    @media screen and (min-width: $screen-desktop) {
        padding-bottom: rem(map-get($spacing, "desktop") / 2);
    }
}

@mixin margin-top {
    margin-top: rem(map-get($spacing, "mobile"));

    @media screen and (min-width: $screen-tablet) {
        margin-top: rem(map-get($spacing, "tablet"));
    }

    @media screen and (min-width: $screen-desktop) {
        margin-top: rem(map-get($spacing, "desktop"));
    }
}

@mixin margin-bottom {
    margin-bottom: rem(map-get($spacing, "mobile"));

    @media screen and (min-width: $screen-tablet) {
        margin-bottom: rem(map-get($spacing, "tablet"));
    }

    @media screen and (min-width: $screen-desktop) {
        margin-bottom: rem(map-get($spacing, "desktop"));
    }
}

@mixin grid {
    @include hh-container;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(map-get($gutter, "mobile"));
    row-gap: rem(map-get($row-gap, "mobile"));

    @media screen and (min-width: $screen-tablet) {
        grid-template-columns: repeat(12, 1fr);
        gap: rem(map-get($gutter, "tablet"));
        row-gap: rem(map-get($row-gap, "tablet"));
    }

    @media screen and (min-width: $screen-desktop) {
        gap: rem(map-get($gutter, "desktop"));
        row-gap: rem(map-get($row-gap, "desktop"));
    }
}

@mixin hh-container {
    padding: rem(map-get($gutter, "mobile"));

    @media screen and (min-width: $screen-tablet) {
        padding: rem(map-get($gutter, "tablet"));
    }

    @media screen and (min-width: $screen-desktop) {
        padding: rem(map-get($gutter, "desktop"));
    }
}

@mixin sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

@mixin black-overlay($opacity: 0.4) {
    width: 100%;
    height: 100%;
    display: block;
    background-color: $black;
    opacity: $opacity;
    position: absolute;
    left: 0;
    top: 0;
}

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }

    &:after {
        clear: both;
    }
}

@function getProperty($map: null, $group: "primary", $key: false, $convertToRem: false) {
    $remable: map-get($map, $group);

    @if $key != false {
        $remable: map-get(map-get($map, $group), $key);
    }

    @if $convertToRem {
        @if $remable {
            @return rem($remable);
        }
    }
    @if not $remable and $key != false {
        @warn "#{$key} in #{$group} does not exist";
        @return 0;
    } @else {
        @if not $remable {
            @warn "#{$group} in #{$map} does not exist!";
            @return 0;
        }
    }
    @return $remable;
}

@mixin fade-in-up($delay: 0s) {
    transition: opacity 1.5s cubic-bezier(0.35, 1, 0.45, 1) $delay, transform 1.5s cubic-bezier(0.35, 1, 0.45, 1) $delay;

    &.not-visible {
        opacity: 0;
        transform: translateY(50px);
    }
}
