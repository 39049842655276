@import "./variables";
@import "./utils";

@font-face {
    font-family: "Helvetica LT Std";
    src: url($asset-path + "HelveticaLTStd-Obl.woff2") format("woff2"),
        url($asset-path + "HelveticaLTStd-Obl.woff") format("woff"),
        url($asset-path + "HelveticaLTStd-Obl.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    src: url($asset-path + "HelveticaLTStd-LightObl.woff2") format("woff2"),
        url($asset-path + "HelveticaLTStd-LightObl.woff") format("woff"),
        url($asset-path + "HelveticaLTStd-LightObl.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    src: url($asset-path + "HelveticaLTStd-Light.woff2") format("woff2"),
        url($asset-path + "HelveticaLTStd-Light.woff") format("woff"),
        url($asset-path + "HelveticaLTStd-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    src: url($asset-path + "HelveticaLTStd-BoldObl.woff2") format("woff2"),
        url($asset-path + "HelveticaLTStd-BoldObl.woff") format("woff"),
        url($asset-path + "HelveticaLTStd-BoldObl.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    src: url($asset-path + "HelveticaLTStd-Bold.woff2") format("woff2"),
        url($asset-path + "HelveticaLTStd-Bold.woff") format("woff"),
        url($asset-path + "HelveticaLTStd-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica LT Std";
    src: url($asset-path + "HelveticaLTStd-Roman.woff2") format("woff2"),
        url($asset-path + "HelveticaLTStd-Roman.woff") format("woff"),
        url($asset-path + "HelveticaLTStd-Roman.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// Setting root sizes and base styles.
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-size: 100%;
    transition: opacity 0s linear;
    transition-delay: 0s;
    opacity: 1 !important;
}

// misc base styles.
body {
    font-family: map-get($bodytype, "font-family"), sans-serif;
    font-style: normal;
    font-weight: map-get($bodytype, regular);
    line-height: 2rem;
    color: $black;
    background-color: $white;
    font-size: 1rem;
}

a {
    color: $black;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        color: $black;
        text-decoration: none;
    }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;

    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-style: inherit;
    font-family: inherit;

    // TODO: get the real values so it looks good in rich text
    margin: 0;
    padding: 0;
}

p,
a,
button,
li {
    font-feature-settings: "kern", "onum", "liga";
    font-size: rem(map-get($bodytype, "base-size"));
    line-height: rem(map-get($bodytype, "base-size") + 12);
    font-family: map-get($bodytype, "font-family"), sans-serif;

    * {
        font-family: map-get($bodytype, "font-family"), sans-serif;
        color: currentColor;
    }
}

small {
    font-size: rem(12);
    line-height: rem(28);
}

// Headings. OpenType ligatures, discretionary ligatures and lining figures enabled if available.
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: map-get($bodytype, "font-family"), sans-serif;
    font-feature-settings: "dlig", "liga", "lnum", "kern";
    font-style: normal;
}

h1 {
    font-size: rem(36);
    line-height: rem(36);
    letter-spacing: rem(-0.75);
    font-weight: map-get($bodytype, bold);
}

h2 {
    font-size: rem(24);
    line-height: rem(28);
    letter-spacing: rem(-0.25);
    font-weight: map-get($bodytype, bold);
    //margin-bottom: rem(12); // case-study-intro
}

h3 {
    font-size: rem(18);
    line-height: rem(22);
    letter-spacing: rem(-0.13);
    font-weight: map-get($bodytype, bold);
}

h4 {
    font-size: rem(14);
    line-height: rem(20);
    letter-spacing: rem(0.25);
    font-weight: map-get($bodytype, regular);
    opacity: 0.55;
}

h5 {
    font-size: rem(map-get($bodytype, "base-size"));
    line-height: rem(map-get($bodytype, "base-size") + 12);
    font-weight: map-get($bodytype, regular);
}

b,
strong {
    font-weight: map-get($bodytype, bold);
}

@media screen and (min-width: $screen-tablet) {
    h1 {
        font-size: rem(48);
        line-height: rem(48);
    }

    h2 {
        font-size: rem(28);
        line-height: rem(32);
        //margin-bottom: rem(80); // case-study-intro
    }

    h3 {
        font-size: rem(20);
        line-height: rem(28);
        letter-spacing: rem(-0.14);
    }
}

@media screen and (min-width: $screen-desktop) {
    p,
    a,
    button,
    li,
    h5 {
        font-size: rem(20);
        line-height: rem(32);
    }

    small {
        font-size: rem(14);
        line-height: rem(32);
    }

    h1 {
        font-size: rem(96);
        line-height: rem(96);
    }

    h2 {
        font-size: rem(48);
        line-height: rem(48);
        //margin-bottom: rem(68); // case-study-intro
    }

    h3 {
        font-size: rem(23);
        line-height: rem(32);
        letter-spacing: rem(-0.16);
    }

    h4 {
        line-height: rem(24);
    }
}
