@import "./variables";
@import "./utils";

.error-page {
    padding-top: rem(180);

    h4 {
        margin-bottom: rem(20);
    }

    h1 {
        margin-bottom: rem(80);
    }

    a {
        display: inline-block;

        &:after {
            content: "";
            display: block;
            height: rem(2);
            margin-top: rem(4);
            width: 100%;
            background-color: currentColor;
            opacity: 0.25;
            transition: opacity 0.4s ease;
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
}

@media screen and (min-width: $screen-tablet) {
    .error-page {
        padding-bottom: rem(40);
        padding-top: rem(160);

        h1 {
            margin-bottom: rem(120);
        }
    }
}

@media screen and (min-width: $screen-desktop) {
    .error-page {
        padding-top: rem(240);

        h4 {
            margin-bottom: rem(40);
        }

        a {
            display: inline-block;

            &:after {
                margin-top: rem(8);
            }
        }
    }
}
